import React from "react"

import Layout from "../components/layout/layout"
import ContactSection from "../components/sections/contact-section"
import Seonew from "../components/seo-new"

const ContactPage = () => (
  <Layout>
    <Seonew title="Contact Us" />
    <ContactSection />
  </Layout>
)

export default ContactPage
