import React from "react"
import JotformEmbed from 'react-jotform-embed'

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const ContactSection = () => (
  <div className="bg-blue-200 w-full md:min-h-screen xl:h-auto px-4 pt-24 md:pt-40 pb-24">
    <div className='w-full max-w-screen-xl grid grid-cols-1 md:grid-cols-2 gap-2 xl:gap-12 mx-auto'>
      <div className="w-full max-w-xl flex flex-col md:p-8 mb-8 md:mb-0">
        <h1 className="text-4xl text-blue-900 font-medium uppercase">Get a <span className='font-bold'>fast</span> website that makes your business look <span className='font-bold'>good</span>.</h1>

        <p className='text-lg text-blue-900 mt-6 mb-4'>We know trying a new service can be scary, so we want to make this <span className='font-extrabold'>100% risk-free</span> for your business. That's why you <span className='font-extrabold'>only pay when you're satisfied and ready to make your project live.</span></p>

        <p className='text-lg text-blue-900 mt-6 mb-4'>No contracts. Just results.</p>

        <p className='text-lg text-blue-900 mt-6 mb-4'>We're booking new Gatsby development projects this {months[new Date().getMonth()]}.</p>

        <a href='mailto:projects@fastweb.dev?subject=fastweb.dev work inquiry'>
          <button className='font-body text-xl bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white font-semibold py-3 px-12 mt-6 mr-0 xl:mr-3 mb-4 uppercase tracking-wider shadow-md'>
            Email Us
          </button>
        </a>
      </div>
      <div>
        <JotformEmbed src="https://form.jotform.com/220450574750048" />
      </div>
    </div>
  </div>
)

export default ContactSection